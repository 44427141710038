<template>
  <div>
    <v-container>
      <h1>CrudProducts {{ nameCommerce }}</h1>
        <p class="mb-4">Esta Vista solo se encarga de crear el comercio para nuestro cliente</p>
      <v-data-table
        :headers="headers"
        :items="commerces"
        sort-by="description"
        class="elevation-1"
        :search="search"
      >
        <v-toolbar flat color="white">
          <v-toolbar-title>Comercios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = true" class="mx-4">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <template #top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Comercios"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-dialog v-model="dialog" max-width="800px">
              <v-card>
                <v-tabs color="deep-purple accent-4" right>
                  <v-tab>Informacion basica</v-tab>

                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-card elevation="0">
                            <v-card-title>
                              <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.name"
                                      label="Nombre comercio"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.description"
                                      label="Descripcion"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.image"
                                      label="imagen"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.promotion"
                                      label="promocion"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-checkbox
                                      v-model="editedItem.active"
                                      label="Activo"
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>

                    <!-- add categories in commerce -->

                  <!-- <v-tab-item>
                    <v-combobox
                        class="mx-4 my-4"
                      v-model="chips"
                      :items="categories.map(category => category.name)"
                      chips
                      clearable
                      label="Agrega tus categorias"
                      multiple
                      prepend-icon="mdi-filter-variant"
                      solo
                    >
                      <template
                        v-slot:selection="{ attrs, item, select, selected }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item }}</strong
                          >&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-tab-item> -->
                </v-tabs>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo comercio
                </v-btn>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="80%">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estas seguro que deseas eliminar este
                  comercio?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="error" text @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template #no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CrudCommerce",
  data: () => ({
    tab: null,
    chips: [],
    items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
    dialog: false,
    dialogDelete: false,
    search: "",
    loading: false,
    headers: [
      { text: "Nombre", value: "name", sortable: true },
      { text: "Descripción", value: "description" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    snackbar: false,
    editedItem: {
      name: "asdasd",
      description: "zxczxczxczxc",
      image: "zxcqweqwe",
      categories: [],
      promotion: 100,
      active: false,
    },
    defaultItem: {
      name: "",
      description: "",
      image: "",
      categories: [],
      promotion: [],
      active: false,
    },
  }),
  computed: {
    ...mapState(["nameCommerce", "apiURL", "commerces", "categories"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo comercio" : "Editar comercio";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.getCategoriesCommerce(this.nameCommerce);
  },
  methods: {
    ...mapActions([
      "getCommerces",
      "createCommerce",
      "commerceUpdate",
      "commerceDelete",
      "getCategoriesCommerce",
    ]),
    remove (item) {
        console.log(item);
        this.chips.splice(this.chips.indexOf(item), 1)
        this.chips = [...this.chips]
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    initialize() {
      this.loading = true;
      this.getCommerces();
    },
    editItem(item) {
      this.editedIndex = this.commerces.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.commerces.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.commerces.splice(this.editedIndex, 1);
      this.commerceDelete(this.editedItem);
      await this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.commerces[this.editedIndex], this.editedItem);
        this.commerceUpdate(this.editedItem);
      } else {
        //   add categories in commerce
        // this.categories.forEach(categorieFather => {
        //     this.chips.forEach(categorieChild => {
        //         if(categorieFather.name == categorieChild){
        //             this.editedItem.categories.push(categorieFather);
        //         }
        //     });
        // });
        this.createCommerce(this.editedItem);
        this.commerces.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>